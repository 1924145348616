<template>
  <b-card
    :title="message.subject"
  >
    <b-card-header

      class="email-detail-head"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap" />
      <div class="mail-meta-item d-flex align-items-center" />

    </b-card-header>

    <b-card-body class="mail-message-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mail-message"
        v-html="message.content"
      />
      <!-- eslint-enable -->
    </b-card-body>

    <b-card-footer v-if="message.attachments && message.attachments.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon
            icon="PaperclipIcon"
            size="16"
          />
          <h5 class="font-weight-bolder text-body mb-0 ml-50">
            {{ message.attachments.length }} Dosya{{ message.attachments.length > 1 ? 'lar' : '' }}
          </h5>
        </div>

        <div class="d-flex flex-column">
          <b-link
            v-for="(attachment, index) in message.attachments"
            :key="index"
            :href="attachment.url"
            target="_blank"
            :class="{'mt-75': index}"
          >

            <span class="text-muted font-weight-bolder align-text-top">{{ attachment.name }}</span>

          </b-link>
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardFooter, BLink,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardHeader, BCardBody, BCardFooter, BLink,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {
    createFileUrl(file) {
      return URL.createObjectURL(file)
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    return {
      formatDate,
    }
  },
}
</script>

<style>

</style>
