<template>

  <div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      ref="mail-send-modal"
      title="Teklifi Mail Gönder"
      size="xl"
      hide-footer
    >
      <mail-send :proposal="selectedProposal" />
    </b-modal>
    <!-- Filters -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input

                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('read','m_proposal_edit')"
                variant="primary"
                :to="{ name: 'proposals-add' }"
              >
                <span class="text-nowrap">{{ $t('Teklif Oluştur') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refProposalListTable"
        class="position-relative"
        :items="fetchProposals"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        small
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(proposal_number)="data">
          <b-media vertical-align="center">

            <b-link
              :href="data.item.pdf_url"
              target="_blank"
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.proposal_number }}
            </b-link>

          </b-media>
        </template>
        <!-- Column: Products -->
        <template #cell(products)="data">
          <b-media vertical-align="center">

            <app-collapse>

              <app-collapse-item :title="$t('Ürünleri Gör')+' ('+data.item.products.length+' '+$t('Ürün')+')'">
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in data.item.products"
                    :key="index"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>{{ item.name }}</span>
                    <b-badge
                      variant="primary"
                      pill
                      class="badge-round"
                    >
                      {{ item.piece+' '+item.unit_name }} /{{ item.stock }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </app-collapse-item>

            </app-collapse>

          </b-media>
        </template>
        <!-- Column: User -->
        <template #cell(validity_days)="data">

          <span>{{ data.item.validity_days }} {{ $t('Gün') }}</span>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="openMailSendModal(data.item)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">{{ $t('Mail Gönder') }} </span>
            </b-dropdown-item>
            <b-dropdown-item
              :href="data.item.pdf_url"
              target="_blank"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t('Pdf Görüntüle') }} </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="true==false"
              :href="data.item.pdf_url"
              target="_blank"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">{{ $t('Pdf Gönder') }} </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('read','m_proposal_edit')"
              :to="{ name: 'proposals-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Düzenle') }} </span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Gösterilen') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalProposals"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BListGroup, BListGroupItem, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useProposalsList from './useProposalList'
import proposalStoreModule from '../proposalsStoreModule'
import mailSend from '../email/EmailSend.vue'

export default {
  components: {
    BCard,
    BRow,
    mailSend,
    BCol,
    BFormInput,
    BButton,
    BModal,
    AppCollapse,
    AppCollapseItem,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    vSelect,
  },
  data() {
    return {
      selectedProposal: null,
    }
  },
  methods: {
    openMailSendModal(proposal) {
      this.selectedProposal = proposal
      this.$refs['mail-send-modal'].show()
    },
    addIsSuccess(id) {
      this.editProposalId = id
      this.isEditProposalSidebarActive = true
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-proposal'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, proposalStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const editProposalId = ref(0)
    const isAddNewProposalSidebarActive = ref(false)
    const isEditProposalSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchProposals,
      tableColumns,
      perPage,
      currentPage,
      totalProposals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProposalListTable,
      refetchData,

      // UI

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useProposalsList()

    return {

      // Sidebar
      isAddNewProposalSidebarActive,
      isEditProposalSidebarActive,
      fetchProposals,
      tableColumns,
      perPage,
      currentPage,
      totalProposals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      editProposalId,
      isSortDirDesc,
      refProposalListTable,
      refetchData,

      // Filter
      avatarText,

      // UI

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
