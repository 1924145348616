<template>
  <div>
    <b-modal
      id="preview-modal"
      :title="$t('E-Posta Önizlemesi')"
      size="xl"
      no-footer
    >
      <email-preview :message="mailData" />
      <hr>

    </b-modal>
    <validation-observer

      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        v-if="mailData"
        ref="mailForm"
        class="p-2"
      >
        <b-form-group

          :label="$t('Müşteri E-Posta Adresi')"
          label-for="email_address_list"
        >
          <b-form-tags
            v-model="mailData.receiver_list"
            input-id="tags-email_address_list"
            :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"

            :placeholder="$t('E Posta Adresi Girip Ardından Enter Tuşuna Basınız..')"
            :tag-validator="emailListValidation"
            separator=" "
          />
          <!-- The following slots are for b-form-group -->
          <template #invalid-feedback>
            {{ $t('Sadece E Posta Formatında Veri Giriniz') }}
          </template>
          <template #description>
            <div id="tags-validation-help">
              {{ $t('Gönderim listesine eklemek istediğiniz e posta adresini kutucuğa yazıp enter tuşuna basarak daha fazla adres ekleyebilirsiniz') }}
            </div>
          </template>
        </b-form-group>
        <b-form-group

          :label="$t('CC')"
          label-for="email_address_list"
        >
          <b-form-tags
            v-model="mailData.cc_list"
            input-id="tags-email_address_list"
            :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"

            :placeholder="$t('E Posta Adresi Girip Ardından Enter Tuşuna Basınız..')"
            :tag-validator="emailListValidation"
            separator=" "
          />
          <!-- The following slots are for b-form-group -->
          <template #invalid-feedback>
            {{ $t('Sadece E Posta Formatında Veri Giriniz') }}
          </template>
          <template #description>
            <div id="tags-validation-help">
              {{ $t('Gönderim listesine eklemek istediğiniz e posta adresini kutucuğa yazıp enter tuşuna basarak daha fazla adres ekleyebilirsiniz') }}
            </div>
          </template>
        </b-form-group>
        <validation-provider
          #default="validationContext"
          :name="$t('Konu')"
          rules="required|min:2"
        >
          <b-form-group
            :label="$t('Konu')"
            label-for="subject"
          >
            <b-form-input
              id="subject"
              v-model="mailData.subject"
              autofocus
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          :name="$t('İçerik')"
          rules="required|min:2"
        >
          <b-form-group
            :label="$t('İçerik')"
            label-for="content"
          >

            <quill-editor
              ref="description"
              v-model="mailData.content"
              :options="snowOption"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="sendMail"
          >
            {{ $t('Gönderim İşlemini Başlat') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.preview-modal
            variant="outline-primary"
          >
            {{ $t('E-Posta Önizlemesi') }}
          </b-button>
        </div>
      </b-form>

    </validation-observer>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  VBModal, BFormGroup, BFormInput, BFormTags, BButton, BFormInvalidFeedback, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import store from '@/store'
import emailPreview from './EmailPreview.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BFormTags,
    emailPreview,
    quillEditor,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    proposal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mailData: {
        subject: '',
        content: '',
        receiver_list: [],
        cc_list: [],
        attachments: [],
        proposal_id: 0,
      },
      required,
      snowOption: {
        theme: 'snow',
      },

    }
  },
  created() {
    this.mailData.receiver_list.push(this.proposal.customer_manager_email)
    this.mailData.cc_list.push(this.proposal.user_email)
    this.mailData.proposal_id = this.proposal.id
    this.mailData.attachments.push({ name: this.proposal.pdf_name, url: this.proposal.pdf_url })
    this.getProposalMailContent()
  },

  methods: {
    getProposalMailContent() {
      store.dispatch('app-proposal/fetchProposalMailContent', { proposal_id: this.mailData.proposal_id })
        .then(response => {
          this.mailData.subject = response.data.data.title
          this.mailData.content = response.data.data.content
        })
    },
    emailListValidation(email) {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (email.match(mailformat)) {
        return true
      }

      return false
    },
    sendMail() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.$swal({
            title: this.$t('Emin Misiniz ?'),
            text: this.$t('E-Postayı göndermeden önce doğruluğundan emin olunuz. Gönderme işlemi geri alınamaz'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('Onayla ve Gönder'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              store.dispatch('app-proposal/sendMail', this.mailData)
                .then(() => {
                  this.$bvToast.toast(this.$t('E Posta Gönderildi'), {
                    title: this.$t('İşlem Başarılı'),
                    variant: 'success',
                    solid: false,
                  })
                })
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped>

.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
